import { useContext, useEffect } from 'react';
import useNRLazyQuery from '../../graphql/useNRLazyQuery';
import isEqual from 'lodash.isequal';
import { QL_ASSET_GROUPS } from '../../graphql/queries/assetGroup';
import { AssetGroupContext } from './assetGroupContextWrapper';

const useFetchAssetGroups = () => {
  const { setAssetGroups, assetGroups, setAssetGroupsLoading } = useContext(AssetGroupContext);

  const [, isGroupsLoading, called, makeRequest] = useNRLazyQuery({
    query: QL_ASSET_GROUPS,
    options: {
      variables: {
        args: {
          cursor: { first: 1000 },
          sort: {
            field: 'NAME',
            order: 'ASC'
          }
        }
      },
      context: { version: 'v3' },
      fetchPolicy: 'cache-and-network'
    },
    qlObjKey: 'assetGroups',
    transformData: data => {
      const groups = data.edges.map(group => group.node);
      if (!isEqual(assetGroups, groups)) {
        setAssetGroups(groups);
      }
    }
  });

  useEffect(() => {
    setAssetGroupsLoading(isGroupsLoading);
  }, [isGroupsLoading]);

  useEffect(() => {
    makeRequest({
      variables: {
        args: {
          cursor: { first: 1000 },
          sort: {
            field: 'NAME',
            order: 'ASC'
          }
        }
      },
      context: { version: 'v3' }
    });
  }, []);
};

export default useFetchAssetGroups;
