import { CONSTANTS } from '../../../constants/constants';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import useFetchAssetGroups from '../../AssetGroup/useFetchAssetGroups';
import useNRQuery from '../../../graphql/useNRQuery';
import { GET_ASSETS_NAMES } from '../../../graphql/queries/asset';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useFetchAsset from '../../../pages/NRReport/useFetchAsset';

function GroupAssetsBreadcrumb({ location, match, groupId, componentId }) {
  const navigate = useNavigate();
  useFetchAssetGroups();
  useFetchAsset();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const theme = useTheme();

  const [assets, loading] = useNRQuery({
    query: GET_ASSETS_NAMES,
    options: {
      context: { version: 'v3' },
      fetchPolicy: 'no-cache',
      variables: {
        args: {
          filter: {},
          cursor: { first: 100 },
          sort: {
            field: CONSTANTS.TABLE.ASSETS.FILTERS.FIELDS.NAME,
            order: CONSTANTS.TABLE.ASSETS.FILTERS.ORDER.ASC
          },
          groupIds: [groupId]
        }
      }
    },
    qlObjKey: 'assetsRelay',
    defaultValue: [],
    transformData: data => data?.edges?.map(item => item.node)
  });

  function onChange(value) {
    const assetFound = assets.find(asset => asset.id === value);
    !!assetFound && setSelectedAsset(assetFound);
  }

  useEffect(() => {
    onChange(componentId);
  }, [assets]);

  useEffect(() => {
    !!selectedAsset && selectedAsset?.id !== componentId && navigate(`/groups/${groupId}/${selectedAsset?.id?.replaceAll('|', '%7C')}`);
  }, [selectedAsset]);

  return (
    <Box>
      {selectedAsset && assets?.length && (
        <Select
          id="asset-selector"
          value={selectedAsset?.id}
          onChange={e => onChange(e.target.value)}
          label={'label'}
          size="small"
          variant="standard"
          autoWidth
          sx={{
            ...theme.typography.body2,
            color: theme.palette.text.icon,
            '&.MuiInput-underline': {
              '&:before': {
                borderBottom: 'none'
              },
              '&:after': {
                borderBottom: 'none'
              },
              '&:hover:not(.Mui-disabled):before': {
                borderBottom: 'none'
              },
              '& > svg': {
                top: theme.spacing(-0.25)
              }
            }
          }}
          MenuProps={{
            MenuListProps: {
              sx: {
                width: '300px'
              }
            }
          }}
        >
          {assets.map(({ id, name, status }) => (
            <MenuItem disabled={status !== CONSTANTS.TABLE.ASSETS.STATUS.COMPLETED} value={id} key={`${id}-${name}`}>
              <Tooltip title={name.length > 35 ? name : ''}>
                <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</Box>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      )}
      {loading && <Typography variant={'body2'}>Loading...</Typography>}
    </Box>
  );
}

export default GroupAssetsBreadcrumb;
