import makeStyles from '@mui/styles/makeStyles';
import NROrgSelectorStyles from './NROrgSelector.styles';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Check from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useContext, useEffect, useState } from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CardHeader from '@mui/material/CardHeader';
import { useAuth0 } from '@auth0/auth0-react';
import { useApolloClient } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { LAST_ORG_USED_KEY } from '../../../utils/ApiConstants';
import { UserContext } from '../../../App';
import { USER_ORGS } from '../../../graphql/queries/user';
import useNRLazyQuery from '../../../graphql/useNRLazyQuery';

const useStyles = makeStyles(NROrgSelectorStyles);

function NROrgSelector() {
  const { currentOrg, setCurrentOrg, currentOrgId, setCurrentOrgId, roles, setRoles, email, setEmail, isChangingOrg, setIsChangingOrg, idToken } =
    useContext(UserContext);
  const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE;
  const permissionsNamespace = auth0Namespace + 'permissions';
  const rolesNamespace = auth0Namespace + 'roles';
  const currentOrgNamespace = auth0Namespace + 'org_name';
  const classes = useStyles();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [disableDropdown, setDisableDropdown] = useState(true);
  const [orgs, setOrgs] = useState(null);
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const [, , , getUserOrgs] = useNRLazyQuery({
    query: USER_ORGS,
    qlObjKey: 'userOrgs',
    options: { context: { version: 'v3' } },
    transformData: userOrgs => {
      setOrgs(userOrgs);
      setDisableDropdown(userOrgs.length < 2);
    }
  });

  useEffect(() => {
    if (idToken && idToken[permissionsNamespace]?.length) {
      getUserOrgs();
    }
  }, [idToken]);

  useEffect(() => {
    location.pathname === '/' && isChangingOrg && setIsChangingOrg(false);
  }, [location]);

  async function handleOnChange(value) {
    if (!value) return;
    setIsChangingOrg(true);

    let idToken = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      organization: value
    });

    if (!!idToken) {
      idToken = await getIdTokenClaims({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      });

      const lastOrgUsedId = localStorage.getItem(idToken.email + LAST_ORG_USED_KEY);

      if (!!idToken.org_id && idToken.org_id !== lastOrgUsedId) {
        localStorage.setItem(idToken.email + LAST_ORG_USED_KEY, idToken.org_id);
        setCurrentOrg(idToken[currentOrgNamespace]);
        setCurrentOrgId(idToken.org_id);
      }

      if (idToken[rolesNamespace]) {
        setRoles(idToken[rolesNamespace]);
      }
      setEmail(idToken?.email);
      client.clearStore();
      navigate(pathname === '/' ? 0 : '/');
    }
    handleClose();
  }

  /**
   * Menu handling
   */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.mainContainer}>
      {!!orgs?.length && (
        <>
          <Button
            id="org-selector-button"
            aria-controls={open ? 'org-selector-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            color="inherit"
            onClick={!disableDropdown ? handleClick : undefined}
            endIcon={!disableDropdown && <KeyboardArrowDown />}
            sx={{ maxHeight: '36px', ...(!disableDropdown && { paddingRight: '12px' }) }}
            classes={{ root: classes.button, disabled: classes.buttonDisabled, endIcon: classes.endIcon }}
            disabled={disableDropdown}
          >
            {currentOrg}
          </Button>
          <Menu
            id="org-selector-menu"
            aria-labelledby="org-selector-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            classes={{ paper: classes.menuPaper }}
          >
            <CardHeader
              titleTypographyProps={{ className: classes.titleStyle }}
              subheaderTypographyProps={{ className: classes.subHeaderStyle }}
              title={email}
              subheader={!!roles && roles?.[0]}
              className={classes.menuHeader}
            />
            <Divider className={classes.menuDivider} />
            {!!orgs &&
              orgs.map(({ Id, DisplayName }) => (
                <MenuItem
                  selected={currentOrgId === Id}
                  classes={{ root: classes.menuItemRoot }}
                  onClick={() => currentOrgId !== Id && handleOnChange(Id)}
                  key={Id}
                >
                  {DisplayName}
                  {currentOrgId === Id && <Check className={classes.selectedIcon} />}
                </MenuItem>
              ))}
          </Menu>
        </>
      )}
    </div>
  );
}
export default NROrgSelector;
