import { DataTypeProvider } from '@devexpress/dx-react-grid';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

const DateFormatter = ({ value, column }) => {
  const valueToUse = value ? (column?.dateFormat ? moment(value).format(column.dateFormat) : value).toString() : '-';
  return (
    <Tooltip title={valueToUse} placement={'top'}>
      <span>{valueToUse}</span>
    </Tooltip>
  );
};
export const DateProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;
