import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { includesPermission } from '../../utils/utils';

const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE;
const permissionsNamespace = auth0Namespace + 'permissions';

function useHasPermission({ permission, defaultValue }) {
  const [hasPermission, setHasPermission] = useState(!!defaultValue);
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    async function asyncFun() {
      const token = await getIdTokenClaims();
      setHasPermission(includesPermission(token[permissionsNamespace], permission));
    }
    asyncFun();
  }, []);

  return hasPermission;
}

export default useHasPermission;
