import { Backdrop, Fade, Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import NRInformationModalStyle from './NRinformationModal.styles';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(NRInformationModalStyle);

function NRInformationModal({ isOpen, setIsOpen, children, width, maxWidth, maxHeight = '60%', paddingContainer = 16, paperOverflow }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <div
          className={classes.paperContainer}
          style={{
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            padding: paddingContainer,
            ...(!!width && { width }),
            ...(!!paperOverflow && { overflow: paperOverflow })
          }}
        >
          <Paper className={classes.paper} elevation={0}>
            {children}
          </Paper>
        </div>
      </Fade>
    </Modal>
  );
}

export default NRInformationModal;
