import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { AssetGroupContext } from '../../AssetGroup/assetGroupContextWrapper';
import useFetchAssetGroups from '../../AssetGroup/useFetchAssetGroups';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function GroupsBreadcrumb({ location, match, groupId }) {
  useFetchAssetGroups();
  const theme = useTheme();
  const { assetGroups, assetGroupsLoading } = useContext(AssetGroupContext);

  const { redirectTo, state } = match.route;
  const selectedAssetGroup = assetGroups?.find(assetGroup => assetGroup.id === groupId);

  return (
    <>
      {assetGroupsLoading ? (
        <Typography variant={'body2'}>Loading...</Typography>
      ) : (
        <>
          {!!selectedAssetGroup?.name && (
            <NavLink to={redirectTo} state={state} style={{ cursor: 'pointer', textDecoration: 'none', color: theme.palette.blue.link }}>
              {selectedAssetGroup?.name}
            </NavLink>
          )}
        </>
      )}
    </>
  );
}

export default GroupsBreadcrumb;
