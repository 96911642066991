import { styled, alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const Container = styled('div')(({ theme, justifyContent }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: justifyContent || 'initial'
}));

const TextStyleBase = styled('span')(
  ({ theme, color, backgroundColor, text, textAlphaValue, alphaValue, fontSize, lineHeight, minWidth, showBorder, justifyContent }) => ({
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, text ? 1 : 0.5),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    color: alpha(color, textAlphaValue),
    backgroundColor: alpha(backgroundColor || color, alphaValue),
    fontSize: fontSize || 'inherit',
    lineHeight: `${lineHeight || fontSize}px` || 'normal',
    minWidth: minWidth || 'auto',
    border: showBorder ? `1px solid ${alpha(color, textAlphaValue)}` : 'none',
    justifyContent: justifyContent || 'initial'
  })
);

function NRHighlightedText({
  style,
  text,
  color,
  backgroundColor,
  icon,
  fontSize,
  lineHeight,
  highlightAll = false,
  alphaValue = 0.15,
  tooltipContent = '',
  minWidth = null,
  showBorder = false,
  justifyContent = null,
  handleOnClick,
  textAlphaValue = 1
}) {
  return (
    <Tooltip title={tooltipContent} placement="top">
      <Container style={style} justifyContent={justifyContent} onClick={handleOnClick}>
        {!highlightAll && icon}
        <TextStyleBase
          color={color}
          backgroundColor={backgroundColor}
          fontSize={fontSize}
          lineHeight={lineHeight}
          text={text}
          alphaValue={alphaValue}
          minWidth={minWidth}
          showBorder={showBorder}
          justifyContent={justifyContent}
          textAlphaValue={textAlphaValue}
        >
          {highlightAll && icon}
          {text}
        </TextStyleBase>
      </Container>
    </Tooltip>
  );
}

export default NRHighlightedText;
