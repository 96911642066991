import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

function EllipsisTypography({ children, variant = 'body2', lines = 1, sx, ...rest }) {
  const typographyRef = useRef(null);
  const [isTypographyOverflowed, setIsTypographyOverflowed] = useState(false);

  useEffect(() => {
    if (typographyRef.current) {
      setIsTypographyOverflowed(
        typographyRef.current.scrollWidth > typographyRef.current.clientWidth ||
          typographyRef.current.scrollHeight > typographyRef.current.clientHeight
      );
    }
  }, [children]);

  return (
    <Tooltip title={isTypographyOverflowed ? children : ''}>
      <Typography
        ref={typographyRef}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: lines,
          lineClamp: lines,
          ...sx
        }}
        component={'div'}
        variant={variant}
        children={children}
        {...rest}
      />
    </Tooltip>
  );
}
export default EllipsisTypography;
