import { gql } from '@apollo/client';

export const USER_ORGS = gql`
  query userOrgs {
    userOrgs {
      DisplayName
      Id
    }
  }
`;

export const USERS = gql`
  query users($args: UsersInput!) {
    users(args: $args) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          createdAt
          disabled
          email
          isOrgDomainUser
          lastSuccessfulLogin
          name
          organization
          passwordDisabled
          picture
          role
          updatedAt
          verified
          deletedAt
          lastPasswordReset
          lastFailedLogin
          failedLoginAttempts
          disabledReason
        }
      }
    }
  }
`;

export const USER_ACTION = gql`
  mutation action($args: UserActionInput!) {
    user {
      action(args: $args) {
        disabled
        id
        name
        organization
        role
        verified
        lastSuccessfulLogin
        email
      }
    }
  }
`;

export const SET_USER_ROLE = gql`
  mutation setUserRole($args: SetUserRoleInput!) {
    user {
      setUserRole(args: $args) {
        disabled
        id
        name
        organization
        role
        verified
        lastSuccessfulLogin
        email
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($args: UserInput!) {
    user {
      resetPassword(args: $args) {
        disabled
        id
        name
        organization
        role
        verified
        lastSuccessfulLogin
        email
      }
    }
  }
`;

export const USER_DELETE = gql`
  mutation delete($args: UserInput!) {
    user {
      delete(args: $args) {
        err
      }
    }
  }
`;

export const USER_REMOVE = gql`
  mutation remove($args: UserInput!) {
    user {
      remove(args: $args) {
        err
      }
    }
  }
`;

export const INVITE_TO_ORGANIZATION = gql`
  mutation invite($args: InviteUserInput!) {
    user {
      invite(args: $args) {
        disabled
        id
        name
        organization
        role
        verified
        lastSuccessfulLogin
        email
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($args: UpdateUserInput!) {
    user {
      updateUser(args: $args) {
        id
        name
      }
    }
  }
`;
