import { styled, useTheme } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NRCircleWarningIcon from '../../NRIcons/NRCircleWarningIcon';
import NRValueTooltipIcon from '../NRReactGridCustomComponents/NRCustomProviders/NRValueTooltipIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

const StyledBoxContainer = styled(Box)(({ theme, selected }) => {
  return {
    cursor: 'pointer',
    padding: theme.spacing(2),
    borderLeft: `solid ${theme.palette.divider} 1px`,
    borderRight: `solid ${theme.palette.divider} 1px`,
    borderTop: `solid ${theme.palette.divider} 1px`,
    '&:first-child': {
      borderRadius: theme.spacing(0.5, 0.5, 0, 0)
    },
    '&:last-child': {
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),
      borderBottom: `solid ${theme.palette.divider} 1px`
    },
    ...(!!selected && {
      border: `solid ${theme.palette.secondary.main} 2px`,
      '&:last-child': {
        borderBottom: `solid ${theme.palette.secondary.main} 2px`
      }
    })
  };
});

function RemediationBox({ title, subtitle, optValue, showAlert = false, avoidSelection = false }) {
  const theme = useTheme();
  const { control, watch } = useFormContext();
  const selectedValue = watch('status');

  return (
    <FormControl fullWidth={true} sx={{ overflowX: 'auto' }}>
      <Controller
        control={control}
        id="remediation-status"
        name="status"
        render={({ field: { onChange } }) => {
          return (
            <StyledBoxContainer
              selected={selectedValue === optValue && !avoidSelection}
              onClick={() => {
                onChange(optValue);
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle1">{title}</Typography>
                <Box display="flex" alignItems="center">
                  {showAlert && (
                    <>
                      <NRCircleWarningIcon color={theme.palette.weightedColors[3][450]} />
                      <Typography sx={{ paddingLeft: 1 }} variant="caption">
                        Removes risk from report.
                      </Typography>
                    </>
                  )}
                  <NRValueTooltipIcon
                    icon={
                      <InfoOutlinedIcon
                        sx={{
                          width: '17px',
                          height: '17px',
                          mt: '-2px',
                          color: theme.palette.text.iconInfo
                        }}
                      />
                    }
                    tooltip={subtitle}
                    style={{ paddingLeft: theme.spacing(1) }}
                  />
                </Box>
              </Box>
            </StyledBoxContainer>
          );
        }}
      />
    </FormControl>
  );
}

export default RemediationBox;
