export default theme => ({
  cardHeader: {
    padding: theme.spacing(2, 3)
  },
  cardHeaderTitle: {
    ...theme.typography.h3
  },
  cardContent: {
    padding: theme.spacing(3, 3, 6),
    fontSize: 16,
    lineHeight: '24px'
  },
  cardActions: {
    padding: theme.spacing(1.75, 3),
    justifyContent: 'flex-end'
  },
  cardActionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2)
    }
  }
});
