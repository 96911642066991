import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import NRButtonProgressStyles from './NRButtonProgress.styles';
import clsx from 'clsx';

const useStyles = makeStyles(NRButtonProgressStyles);

function NRButtonProgress({ className, label, variant = 'contained', color = 'secondary', type, onClick, isLoading, disabled, id, extraStyles }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.wrapper, className)}>
      <Button
        data-testid={'button-progress'}
        id={id}
        fullWidth
        className={className}
        variant={variant}
        color={color}
        onClick={onClick}
        type={type}
        disabled={isLoading || disabled}
        sx={{ ...extraStyles }}
      >
        {label}
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}

export default NRButtonProgress;
