import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import X from 'react-feather/dist/icons/x';
import { styled } from '@mui/material/styles';
import { CONSTANTS } from '../../constants/constants';
const { CONFIRM, CANCEL, CLOSE_TOOLTIP } = CONSTANTS.CONFIRMATION_MODAL;
import NRActionModalStyles from './NRActionModal.styles';
const useStyles = makeStyles(NRActionModalStyles);

export const ModalMainContainer = styled('div')(({ theme, width }) => ({
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%)`,
  color: theme.palette.background.paper,
  position: 'absolute',
  width: width,
  maxHeight: '92vh',
  overflowY: 'auto',
  /* Hide scrollbar for IE, Edge and Firefox */
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none',
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}));

function NRActionModal({
  title,
  actionLabel = CONFIRM,
  cancelLabel = CANCEL,
  subtitle,
  content,
  open,
  handleClose,
  handleConfirmAction,
  handleCancelAction,
  actionButtonProps = { variant: 'contained', color: 'primary' },
  cancelButtonProps = { variant: 'contained', color: 'secondary' },
  width = 500
}) {
  const classes = useStyles();

  const body = (
    <ModalMainContainer width={width}>
      <Card elevation={0}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.cardHeaderTitle }}
          title={title}
          subheader={subtitle}
          action={
            <Tooltip title={CLOSE_TOOLTIP} placement="right">
              <IconButton aria-label="Close" onClick={handleClose} size="large">
                <X />
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent classes={{ root: classes.cardContent }}>{content}</CardContent>
        {(handleCancelAction || handleConfirmAction) && (
          <CardActions classes={{ root: classes.cardActions, spacing: classes.cardActionsSpacing }}>
            {handleCancelAction && (
              <Button {...cancelButtonProps} onClick={() => handleCancelAction()}>
                {cancelLabel}
              </Button>
            )}
            {handleConfirmAction && (
              <Button {...actionButtonProps} onClick={() => handleConfirmAction()}>
                {actionLabel}
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </ModalMainContainer>
  );

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      {body}
    </Modal>
  );
}
export default NRActionModal;
