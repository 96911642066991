import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';

const HelpDrawerKnob = styled(Button)(({ theme, isOpen, top }) => ({
  minWidth: 'auto',
  borderTopLeftRadius: 20,
  zIndex: 501,
  top: top,
  borderBottomLeftRadius: 20,
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  color: theme.palette.text.icon,
  position: isOpen ? 'absolute' : 'fixed',
  left: isOpen ? -30 : 'auto',
  right: !isOpen ? 0 : 'auto',
  border: !isOpen ? `solid 1px ${alpha(theme.palette.neutral[200], 0.4)}` : 'none',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    ...(isOpen ? {} : { border: `solid 1px ${theme.palette.neutral[200]}` })
  }
}));

function NRHelpDrawerKnob({ label = '?', top, onclick, isOpen }) {
  return (
    <HelpDrawerKnob top={top} isOpen={isOpen} onClick={() => onclick(!isOpen)}>
      {label}
    </HelpDrawerKnob>
  );
}
export default NRHelpDrawerKnob;
