import { makeStyles } from '@mui/styles';
import NRSuperIndexAndValueStyles from './NRSuperIndexAndValue.styles';
import clsx from 'clsx';
import UpperCaseGreyText from '../UpperCaseGreyText/UpperCaseGreyText';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
const useStyles = makeStyles(NRSuperIndexAndValueStyles);

function NRSuperIndexAndValue({ superIndex, value, extraClass, valueColor }) {
  const theme = useTheme();
  const classes = useStyles();
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const divElement = document.getElementById(`value-container-${superIndex}`);
    setIsOverflowing(divElement.scrollWidth > divElement.clientWidth);
  }, []);

  return (
    <div className={clsx(classes.mainContainer, extraClass)}>
      <UpperCaseGreyText text={superIndex} />
      <Tooltip title={isOverflowing ? value : ''}>
        <Box
          id={`value-container-${superIndex}`}
          className={clsx(classes.value, classes.overflow)}
          color={valueColor || theme.palette.text.secondary}
        >
          {value}
        </Box>
      </Tooltip>
    </div>
  );
}
export default NRSuperIndexAndValue;
