import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import makeStyles from '@mui/styles/makeStyles';
import ReportBreadcrumbStyles from './ReportBreadcrumb.styles';
import { useSnackbar } from 'notistack';
import { CONSTANTS } from '../../../constants/constants';
import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { ComponentContext } from '../../../ContextWrappers/NRComponentContextWrapper';
const useStyles = makeStyles(ReportBreadcrumbStyles);

function ReportBreadcrumb() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { asset } = useContext(ComponentContext);

  const onCopyJobGUID = () => {
    navigator.clipboard.writeText(window.location.href);
    enqueueSnackbar(CONSTANTS.REPORT_URL_COPIED, { variant: 'success', preventDuplicate: true, autoHideDuration: 1000 });
  };

  return (
    <>
      {!!asset?.name && (
        <Typography variant="body2" component="span" className={classes.cursor} onClick={onCopyJobGUID}>
          {asset?.name}
          <FileCopyOutlinedIcon className={classes.icon} />
        </Typography>
      )}
    </>
  );
}

export default ReportBreadcrumb;
