import { useEffect, useContext } from 'react';
import useNRLazyQuery from '../../graphql/useNRLazyQuery';
import { GET_ASSET } from '../../graphql/queries/asset';
import { ComponentContext } from '../../ContextWrappers/NRComponentContextWrapper';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash.isequal';

const useFetchAsset = () => {
  const { setAsset, asset, refetch, setRefetch } = useContext(ComponentContext);
  const { componentId } = useParams();

  const [, , called, makeRequest] = useNRLazyQuery({
    query: GET_ASSET,
    options: { variables: { assetId: componentId }, context: { version: 'v3' }, fetchPolicy: 'cache-and-network' },
    qlObjKey: 'asset',
    transformData: data => {
      if (!isEqual(asset, data)) {
        setAsset(data);
      }
    }
  });

  useEffect(() => {
    if ((!asset && componentId && !called) || asset.id !== componentId || !!refetch) {
      setRefetch(false);
      makeRequest({ variables: { args: { assetId: componentId } } });
    }
  }, [componentId, refetch]);
};

export default useFetchAsset;
