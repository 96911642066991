import MenuItem from '@mui/material/MenuItem';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import makeStyles from '@mui/styles/makeStyles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { GET_SIGNED_URL_DOWNLOAD_COMPONENT, GET_SIGNED_URL_DOWNLOAD_EXTRACTED_COMPONENT } from '../../graphql/queries/asset';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import NRAssetsActionsStyles from './NRAssetsActions.styles';
import useNRLazyQuery from '../../graphql/useNRLazyQuery';
import NRAnalysisLog from '../../components/NRAnalysisLog/NRAnalysisLog';
import CustomError from '../../components/CustomError/CustomError';
import { AssetsContext } from '../../DataShare/NRAssetsDataShare';
import { NRMenu } from '../../components/NRMenu/NRMenu';
import { CONSTANTS } from '../../constants/constants';
import WithPermissions from '../../components/Permissions/WithPermissions';
import NRGroupIcon from '../../NRIcons/NRGroupIcon';
import * as React from 'react';
import { AssetGroupContext } from '../../components/AssetGroup/assetGroupContextWrapper';
import Typography from '@mui/material/Typography';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import RemoveFromGroupModal from '../../components/AssetGroup/RemoveFromGroupModal/RemoveFromGroupModal';
const useStyles = makeStyles(NRAssetsActionsStyles);

function NRAssetsActions({ component, anchorEl, setAnchorEl, onMenuClose, handleEditClick, assetGroupId }) {
  const [analysisLogModalJobID, setAnalysisLogModalJobID] = useState(null);
  const [operationTask, setOperationTask] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const [componentId, setComponentId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { tasks, setTasks } = useContext(AssetsContext);
  const { setShowManageGroupsModal, setGroupsModalContent, setSelectedAsset } = useContext(AssetGroupContext);
  const location = useLocation();

  useEffect(() => {
    !!component && setComponentId(component?.id || component?.componentId);
  }, [component]);

  const handleMenuClose = () => {
    setAnchorEl(null);
    !!onMenuClose && onMenuClose();
  };

  const handlePivotToSearch = () => {
    handleMenuClose();
    window.open('/search?assetId=' + componentId, '_blank');
  };

  const [assetDownload, isAssetDownloadLoading, , getAssetDownloadOperationId] = useNRLazyQuery({
    query: GET_SIGNED_URL_DOWNLOAD_COMPONENT,
    qlObjKey: 'download',
    options: { fetchPolicy: 'no-cache', context: { version: 'v3' } },
    defaultValue: '',
    transformData: data => {
      return data?.firmware?.operationId;
    }
  });

  const [extractedAssetDownload, isExtractedAssetDownloadLoading, , getExtractedAssetDownloadOperationId] = useNRLazyQuery({
    query: GET_SIGNED_URL_DOWNLOAD_EXTRACTED_COMPONENT,
    qlObjKey: 'download',
    options: { fetchPolicy: 'no-cache', context: { version: 'v3' } },
    defaultValue: '',
    transformData: data => {
      return data?.extractedFirmware?.operationId;
    }
  });

  function handleViewAnalysisLogClick() {
    !!componentId && setAnalysisLogModalJobID(componentId);
    handleMenuClose();
  }

  function handleAnalysisLogModalClose() {
    setAnalysisLogModalJobID(null);
  }

  const downloadComponent = () => {
    if (isAssetDownloadLoading) return;
    getAssetDownloadOperationId({ variables: { args: { componentId } } });
  };

  const downloadExtractedComponent = () => {
    if (isExtractedAssetDownloadLoading) return;
    getExtractedAssetDownloadOperationId({ variables: { args: { componentId } } });
  };

  useEffect(() => {
    if (assetDownload) {
      setOperationTask({ operationId: assetDownload, isExtracted: false });
    }
  }, [assetDownload]);

  useEffect(() => {
    if (extractedAssetDownload) {
      setOperationTask({ operationId: extractedAssetDownload, isExtracted: true });
    }
  }, [extractedAssetDownload]);

  const handleManageGroupsClick = () => {
    setSelectedAsset(component?.row || component);
    setShowManageGroupsModal(true);
    handleMenuClose();
  };

  const handleDeleteGroupsClick = () => {
    setGroupsModalContent({ content: <RemoveFromGroupModal assetId={componentId} groupId={assetGroupId} />, title: 'Remove from Group' });
    handleMenuClose();
  };

  useEffect(() => {
    async function asyncFun() {
      if (!!operationTask?.operationId) {
        if (tasks.find(e => e.operationId === operationTask.operationId && e.status === CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.IN_PROGRESS)) {
          enqueueSnackbar(<CustomError message={CONSTANTS.DOWNLOAD.ALREADY_IN_PROGRESS_MESSAGE} />, {
            variant: 'warning',
            preventDuplicate: true
          });
          return;
        }
        setTasks(prev => [
          {
            operationId: operationTask.operationId,
            fileName: `${component?.name || component?.component?.name}${operationTask.isExtracted ? '_extracted.tar.gz' : ''}`,
            status: CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.IN_PROGRESS, //check UPLOAD keyword
            type: CONSTANTS.TASKS.TYPE.DOWNLOAD,
            id: uuidv4()
          },
          ...prev
        ]);
      }
    }
    asyncFun();
  }, [operationTask]);

  return (
    <div>
      <NRMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {!!handleEditClick && (
          <WithPermissions permission={CONSTANTS.PERMISSIONS.WRITE_IMGS}>
            <MenuItem onClick={handleEditClick} disableRipple className={clsx(classes.item, classes.firstItem)}>
              <ModeEditOutlineOutlinedIcon />
              Edit Asset Details
            </MenuItem>
          </WithPermissions>
        )}
        <MenuItem
          onClick={handleManageGroupsClick}
          disableRipple
          className={clsx(classes.item, classes.groupsIcon, { [classes.firstItem]: !handleEditClick })}
        >
          <NRGroupIcon width={'24'} height={'24'} />
          <Typography sx={{ pl: 1.5 }}>Manage Groups</Typography>
        </MenuItem>
        <MenuItem onClick={handleViewAnalysisLogClick} disableRipple className={classes.item}>
          <DescriptionOutlinedIcon />
          View Analysis Log
        </MenuItem>
        {/* To be uncommented when the functionality si developed. */}
        <MenuItem onClick={() => downloadComponent()} disableRipple className={classes.item}>
          {!isAssetDownloadLoading && <FileDownloadOutlinedIcon />}
          {isAssetDownloadLoading && <CircularProgress className={classes.downloadLoading} size={20} />}
          Download Asset
        </MenuItem>
        <MenuItem onClick={() => downloadExtractedComponent()} disableRipple className={classes.item}>
          {!isExtractedAssetDownloadLoading && <FileDownloadOutlinedIcon />}
          {isExtractedAssetDownloadLoading && <CircularProgress className={classes.downloadLoading} size={20} />}
          Download Extracted Asset
        </MenuItem>
        {!handleEditClick && (
          <MenuItem onClick={handlePivotToSearch} disableRipple className={classes.item}>
            <PageviewOutlinedIcon />
            Pivot To Search
          </MenuItem>
        )}
        <WithPermissions permission={CONSTANTS.PERMISSIONS.DELETE_GROUPS}>
          <>
            {location?.pathname === '/groups' && <Divider sx={{ my: 0.5 }} />}
            {location?.pathname === '/groups' && (
              <MenuItem onClick={handleDeleteGroupsClick} disableRipple className={classes.item}>
                <HighlightOffOutlinedIcon />
                Remove From Group
              </MenuItem>
            )}
          </>
        </WithPermissions>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleMenuClose} disableRipple className={clsx(classes.item, classes.lastItem)}>
              <DeleteOutlinedIcon />
              Delete Asset
            </MenuItem> */}
      </NRMenu>
      {analysisLogModalJobID !== null && <NRAnalysisLog handleClose={handleAnalysisLogModalClose} open={true} componentId={analysisLogModalJobID} />}
    </div>
  );
}
export default NRAssetsActions;
