import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  }
}));

function NRLink({ tooltipTitle, text, url, color }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Tooltip title={tooltipTitle || ''}>
      <Link
        onClick={event => {
          event.preventDefault();
          window.open(url, '_blank', 'noopener');
        }}
        className={classes.link}
        color={color || theme.palette.blue.link}
      >
        {text || url}
        <CallMadeOutlinedIcon style={{ maxWidth: 16, maxHeight: 16, marginLeft: 4, marginBottom: -3 }} />
      </Link>
    </Tooltip>
  );
}

export default NRLink;
