import NRReactGrid from '../../../NRReactGrid/NRReactGrid';
import { DateProvider } from '../DateProvider';
import { NRAssetGroupNameLinkProvider } from '../NRAssetGroupNameLinkProvider/NRAssetGroupNameLinkProvider';
import moment from 'moment/moment';
import { CONSTANTS } from '../../../../constants/constants';
import clsx from 'clsx';
import * as React from 'react';
import { NRAssetGroupDeleteProvider } from '../NRAssetGroupDeleteProvider/NRAssetGroupDeleteProvider';

const assetGroupColumnsExtensions = [
  {
    columnName: 'name',
    align: 'left',
    width: 280
  },
  {
    columnName: 'updatedAt',
    align: 'left',
    width: 280
  }
];

const assetGroupColumns = [
  {
    title: { value: 'Group Name' },
    name: 'name'
  },
  {
    title: { value: 'Assets' },
    name: 'assetsCount'
  },
  {
    title: { value: 'Last Modified' },
    name: 'updatedAt',
    dateFormat: CONSTANTS.DATE_FORMAT.MONTH_DAY_YEAR_TIME
  },
  {
    title: { value: '' },
    name: 'id'
  }
];

function AssetGroupGrid({ rows }) {
  return (
    <NRReactGrid
      pageSize={5}
      pageSizes={[5]}
      rows={rows}
      columns={assetGroupColumns}
      columnExtensions={assetGroupColumnsExtensions}
      showTitle={false}
      providersArray={[
        <NRAssetGroupNameLinkProvider key="nameLinkProvider" for={['name']} />,
        <DateProvider key="dateProvider" for={['updatedAt']} />,
        <NRAssetGroupDeleteProvider key="deleteProvider" for={['id']} />
      ]}
      showIntegratedSelection={false}
      showIntegratedPagination={true}
      showSelectAll={false}
      statesArray={[]}
      renderersArray={[]}
      addDefaultSorting={true}
      defaultSortingColumn={'name'}
      defaultSortingOrder={'asc'}
    />
  );
}

export default AssetGroupGrid;
