import { useContext } from 'react';
import NRMenu from '../../pages/NRMenu/NRMenu';
import NRBreadcrumbs from '../NRBreadcrumbs/NRBreadcrumbs';
import NRHelpDrawer from '../NRHelpDrawer/NRHelpDrawer';
import NRTaskManager from '../NRTaskManager/NRTaskManager';
import { AssetGroupContext } from '../AssetGroup/assetGroupContextWrapper';
import AssetGroupProviderModal from '../NRReactGridCustomComponents/NRCustomProviders/AssetGroupProvider/AssetGroupProviderModal';

function NRLayout({ children }) {
  const { showManageGroupsModal } = useContext(AssetGroupContext);
  return (
    <NRMenu>
      {!!showManageGroupsModal && <AssetGroupProviderModal />}
      <NRHelpDrawer />
      <NRBreadcrumbs />
      <NRTaskManager />
      {children}
    </NRMenu>
  );
}
export default NRLayout;
