export default theme => ({
  container: {
    marginLeft: theme.spacing(2)
  },
  button: {
    textTransform: 'unset',
    fontWeight: 600
  },
  item: {
    padding: theme.spacing(1.5, 2)
  },
  link: {
    textDecoration: 'unset',
    color: 'unset',
    display: 'contents'
  },
  firstItem: {
    marginTop: theme.spacing(1)
  },
  lastItem: {
    marginBottom: theme.spacing(1)
  },
  downloadLoading: {
    marginRight: theme.spacing(1.25)
  },
  groupsIcon: {
    '& > svg': {
      opacity: 0.7
    }
  }
});
