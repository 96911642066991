import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { REMEDIATION_CONSTANTS } from './NRRemediation.constants';
import React from 'react';
import NRValueTooltipIcon from '../NRReactGridCustomComponents/NRCustomProviders/NRValueTooltipIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemediationBox from './RemediationBox';

function NRRemediationStepOne() {
  const theme = useTheme();
  return (
    <Box mt={2} mb={1}>
      <Box>
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.UNSPECIFIED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.UNSPECIFIED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.UNSPECIFIED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.UNSPECIFIED}
        />
      </Box>
      <Box display={'flex'} alignItems={'center'} py={2} pl={1}>
        <Typography sx={{ paddingRight: 1 }} variant={'body2'}>
          VEX Statuses
        </Typography>
        <NRValueTooltipIcon
          icon={
            <InfoOutlinedIcon
              sx={{
                width: '17px',
                height: '17px',
                mt: '-2px',
                color: theme.palette.text.iconInfo
              }}
            />
          }
          tooltip={REMEDIATION_CONSTANTS.VEX_TOOLTIP}
        />
      </Box>
      <Box>
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.AFFECTED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.AFFECTED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.AFFECTED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.AFFECTED}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.FALSE_POSITIVE}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.FALSE_POSITIVE}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.FALSE_POSITIVE}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.FALSE_POSITIVE}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.UNDER_INVESTIGATION}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.UNDER_INVESTIGATION}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.UNDER_INVESTIGATION}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.UNDER_INVESTIGATION}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.NOT_AFFECTED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.NOT_AFFECTED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.NOT_AFFECTED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.NOT_AFFECTED}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.FIXED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.FIXED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.FIXED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.FIXED}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.RESOLVED_WITH_PEDIGREE}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.RESOLVED_WITH_PEDIGREE}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.RESOLVED_WITH_PEDIGREE}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.RESOLVED_WITH_PEDIGREE}
        />
      </Box>
    </Box>
  );
}

export default NRRemediationStepOne;
