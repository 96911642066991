import { gql } from '@apollo/client';

export const QL_ASSET_GROUPS = gql`
  query assetGroups($args: AssetGroupsInput!) {
    assetGroups(args: $args) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          id
          name
          description
          assetsCount
          updatedAt
          highestAssetRisk
        }
      }
    }
  }
`;

export const QL_SET_ASSETS_TO_ASSET_GROUP = gql`
  mutation setAssetsToAssetGroup($args: SetAssetsToAssetGroupInput!) {
    setAssetsToAssetGroup(args: $args)
  }
`;

export const QL_DELETE_ASSET_GROUP = gql`
  mutation deleteAssetGroup($args: DeleteAssetGroupInput!) {
    deleteAssetGroup(args: $args)
  }
`;

export const QL_REMOVE_FROM_GROUP = gql`
  mutation removeAssetsFromAssetGroup($args: RemoveAssetsFromAssetGroupInput!) {
    removeAssetsFromAssetGroup(args: $args)
  }
`;

export const QL_SET_GROUPS_TO_ASSET = gql`
  mutation setAssetGroupsToAsset($args: SetAssetGroupsToAssetInput!) {
    setAssetGroupsToAsset(args: $args)
  }
`;

export const QL_ADD_ASSET_GROUPS_TO_ASSETS = gql`
  mutation addAssetGroupsToAssets($args: AddAssetGroupsToAssetsInput!) {
    addAssetGroupsToAssets(args: $args)
  }
`;

export const QL_REMOVE_ASSET_GROUPS_FROM_ASSETS = gql`
  mutation removeAllAssetGroupsFromAssets($args: RemoveAllAssetGroupsFromAssetsInput!) {
    removeAllAssetGroupsFromAssets(args: $args)
  }
`;

export const QL_CREATE_ASSET_GROUP = gql`
  mutation createAssetGroup($args: CreateAssetGroupInput!) {
    createAssetGroup(args: $args) {
      result
      assetGroup {
        id
        name
        description
      }
    }
  }
`;

export const QL_UPDATE_ASSET_GROUP = gql`
  mutation updateAssetGroup($args: UpdateAssetGroupInput!) {
    updateAssetGroup(args: $args) {
      result
      assetGroup {
        id
        name
        description
      }
    }
  }
`;

export const QL_ADD_ASSETS_TO_ASSET_GROUP = gql`
  mutation addAssetsToAssetGroup($args: AddAssetsToAssetGroupInput!) {
    addAssetsToAssetGroup(args: $args)
  }
`;
