import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NRButtonProgress from '../../NRButtonProgress/NRButtonProgress';
import useNRMutation from '../../../graphql/UseNRMutation';
import { QL_ASSET_GROUPS, QL_REMOVE_FROM_GROUP } from '../../../graphql/queries/assetGroup';
import { useContext, useEffect } from 'react';
import { AssetGroupContext } from '../assetGroupContextWrapper';
import { CONSTANTS } from '../../../constants/constants';
import { GET_ASSET_GROUP_ANALYTIC, GET_ASSETS } from '../../../graphql/queries/asset';

function RemoveFromGroupModal({ assetId, groupId }) {
  const { setGroupsModalContent, assetsRelayArgs } = useContext(AssetGroupContext);
  const [removeFromGroup, removeFromGroupResponse, loading, error] = useNRMutation(
    QL_REMOVE_FROM_GROUP,
    'removeAssetsFromAssetGroup',
    CONSTANTS.MESSAGES.ASSET_REMOVED_FROM_GROUP_SUCCESSFULLY,
    CONSTANTS.MESSAGES.ASSET_REMOVED_FROM_GROUP_ERROR,

    {
      refetchQueries: [
        {
          query: QL_ASSET_GROUPS,
          variables: {
            args: {
              cursor: { first: 1000 },
              sort: {
                field: 'NAME',
                order: 'ASC'
              }
            }
          },
          context: { version: 'v3' }
        },
        { query: GET_ASSETS, variables: { args: assetsRelayArgs }, context: { version: 'v3' } },
        { query: GET_ASSET_GROUP_ANALYTIC, variables: { args: { groupId } }, context: { version: 'v3' } }
      ]
    }
  );

  const handleRemove = () => {
    removeFromGroup({ variables: { args: { id: groupId, assetIds: [assetId] } } });
  };

  useEffect(() => {
    if (removeFromGroupResponse) {
      setGroupsModalContent(null);
    }
  }, [removeFromGroupResponse]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box>
        <Typography variant={'body1'}>This will remove the asset from the group, the asset will not be deleted. </Typography>
      </Box>
      <Box py={2}>
        <Typography variant={'body1'}>Are you sure you want to do this?</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={'auto'} ml={'auto'}>
        <Button variant={'outlined'} color={'secondary'} onClick={() => setGroupsModalContent(null)} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <NRButtonProgress
          variant="contained"
          color="secondary"
          disabled={!!loading}
          isLoading={loading}
          type={'button'}
          label={'Yes, Remove'}
          onClick={handleRemove}
        />
      </Box>
    </Box>
  );
}

export default RemoveFromGroupModal;
