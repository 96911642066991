export default theme => ({
  listItem: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  selectButton: {
    backgroundColor: theme.palette.background.paper,
    padding: `8.5px 6px 8.5px ${theme.spacing(1.75)}`,
    border: `1px solid ${theme.palette.input.border}`,
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    '&:hover,&:focus': {
      color: 'inherit',
      borderColor: theme.palette.text.icon,
      border: `1px solid ${theme.palette.input.border}`
    },
    height: theme.spacing(5)
  },
  check: {
    width: 17,
    height: 17,
    marginRight: '5px',
    marginLeft: '-2px'
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    '& p': {
      color: theme.palette.text.primary
    }
  }
});
