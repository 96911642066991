function NRGroupIcon({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg height={height} width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 16C9.55 16 10.0208 15.8042 10.4125 15.4125C10.8042 15.0208 11 14.55 11 14C11 13.45 10.8042 12.9792 10.4125 12.5875C10.0208 12.1958 9.55 12 9 12C8.45 12 7.97917 12.1958 7.5875 12.5875C7.19583 12.9792 7 13.45 7 14C7 14.55 7.19583 15.0208 7.5875 15.4125C7.97917 15.8042 8.45 16 9 16ZM15 16C15.55 16 16.0208 15.8042 16.4125 15.4125C16.8042 15.0208 17 14.55 17 14C17 13.45 16.8042 12.9792 16.4125 12.5875C16.0208 12.1958 15.55 12 15 12C14.45 12 13.9792 12.1958 13.5875 12.5875C13.1958 12.9792 13 13.45 13 14C13 14.55 13.1958 15.0208 13.5875 15.4125C13.9792 15.8042 14.45 16 15 16ZM12 11C12.55 11 13.0208 10.8042 13.4125 10.4125C13.8042 10.0208 14 9.55 14 9C14 8.45 13.8042 7.97917 13.4125 7.5875C13.0208 7.19583 12.55 7 12 7C11.45 7 10.9792 7.19583 10.5875 7.5875C10.1958 7.97917 10 8.45 10 9C10 9.55 10.1958 10.0208 10.5875 10.4125C10.9792 10.8042 11.45 11 12 11ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
        fill={color}
      />
    </svg>
  );
}
export default NRGroupIcon;
